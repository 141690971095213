<template>
  <div>
    <v-container class="text-center pa-3">
      <v-card class="mx-auto" max-width="350">
        <v-system-bar height="47" color="primary">
          <h3 class="white--text">Visit Log Manager</h3>
          <v-spacer></v-spacer>
        </v-system-bar>
        <template slot="progress">
          <v-progress-linear
            color="deep-purple"
            height="10"
            indeterminate
          ></v-progress-linear>
        </template>
        <v-divider class="mx-4"></v-divider>
        <v-card-text class="pa-2">
          <div class="col-lg-12 col-sm-12 text-center">
            <StreamBarcodeReader
              @decode="onDecode"
              @loaded="onLoaded"
            ></StreamBarcodeReader>
          </div>
        </v-card-text>
      </v-card>
      <v-overlay :absolute="false" :value="loading">
        <v-progress-circular
          :size="200"
          color="primary"
          indeterminate
        ></v-progress-circular>
      </v-overlay>
    </v-container>
  </div>
</template>
<script>
//import Vue from "vue";
import { StreamBarcodeReader } from "vue-barcode-reader";
export default {
  created() {},
  watch: {},
  props: {},
  newd() {},
  components: {
    StreamBarcodeReader,
  },
  data() {
    return {
      form: {},
      loading: false,
    };
  },
  methods: {
    onDetect(code) {
      console.log(code);
      this.showInfo(code);
    },
    onDecode(text) {
      console.log(`Decode text from QR code is ${text}`);
      this.showInfo(text);
    },
    onLoaded() {
      console.log(`Ready to start scanning barcodes`);
    },
  },
  name: "QRScanner",
};
</script>
